import { useCallback } from 'react'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_USER_FROZEN } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useHandleDeleteOfferOnOfferPage } from 'src/hooks/offer.hooks'
import {
  OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS,
  OFFER_STATUS_TO_PERMITTED_ACTIONS,
  OfferActions,
  TOfferActionsMap,
} from 'src/models/offerActions.model'
import { IPropsWithOffer } from 'src/models/post.model'
import SuccessOfferPosted from 'src/routes/newItem/components/form/SuccessOfferPosted'
import { useCurrentUser } from 'src/services/currentUser.service'
import { useGetOffer } from 'src/services/post.service'
import { useToggle } from 'src/utils/hooks.utils'
import { handleRepublishOffer, isPostClosed } from 'src/utils/post.utils'

import StatisticsSection from './OfferStatistics'
import PostActionDescription from './PostStatusDescription'

import styles from './styles.module.scss'

const ClosedPostActions = ({ offer }: IPropsWithOffer) => {
  const handleDeletePostOnPostPage = useHandleDeleteOfferOnOfferPage(offer)
  const [isSuccessOpen, toggleSuccessOpen] = useToggle()
  const { mutate } = useGetOffer(SWRkeys.getPublicOffer(offer.slug!))
  const { slug } = offer
  const { data: currentUser } = useCurrentUser()

  const isDisabled = currentUser?.isFrozen

  const handleArchivedOfferBtnClick = useCallback(() => {
    handleRepublishOffer(slug, toggleSuccessOpen)
  }, [slug, toggleSuccessOpen])

  const onSuccessClose = useCallback(() => {
    toggleSuccessOpen()
    mutate()
  }, [mutate, toggleSuccessOpen])

  if (!isPostClosed(offer.status)) {
    return null
  }

  const offerActions: TOfferActionsMap = {
    [OfferActions.republish]: {
      onClick: handleArchivedOfferBtnClick,
      title: isDisabled ? `${I18N_USER_FROZEN}.publishOfferButtonTooltip` : undefined,
      disabled: isDisabled,
    },
    [OfferActions.edit]: {
      linkTo: isDisabled ? undefined : YomeURL.editOffer(offer.slug),
      title: isDisabled ? `${I18N_USER_FROZEN}.editOfferButtonTooltip` : undefined,
      disabled: isDisabled,
    },
    [OfferActions.delete]: {
      onClick: handleDeletePostOnPostPage,
    },
  }

  const actionsListForStatus = OFFER_STATUS_TO_PERMITTED_ACTIONS(offer.publishedAt)[offer.status]

  return (
    <div className={styles.mainPostOwner}>
      <PostActionDescription postType={offer.status} />
      <StatisticsSection offer={offer} />
      <div className={styles.postOwnerActionButtons}>
        {actionsListForStatus.map((action, index) => {
          const actionProps = action in offerActions ? offerActions[action] : null

          return offerActions ? (
            <RegularButton
              key={action}
              buttonType={index === 0 ? RegularButtonTypes.main : RegularButtonTypes.transparentWithBorder}
              {...OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS[action]}
              {...actionProps}
            />
          ) : null
        })}
      </div>
      { isSuccessOpen && (
        <SuccessOfferPosted
          category={offer.category!}
          slug={offer.slug}
          title={offer.title}
          onClose={onSuccessClose}
        />
      )}
    </div>
  )
}

export default ClosedPostActions
