import { useCallback } from 'react'
import { toast } from 'react-toastify'
import useSWR from 'swr'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import UserFrozenNotification from 'src/components/UserFrozenNotification/UserFrozenNotification'
import { I18N_GLOBAL, I18N_MESSENGER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { IChat } from 'src/models/messenger.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { handleUnblockUser } from 'src/services/messenger.service'

import ChatHeader from '../chatHeader/ChatHeader'
import ChatHistory from '../chatHistory/ChatHistory'
import MessageForm from '../chatMessageForm/ChatMessageForm'
import NoDialog from '../noDialog/NoDialog'

import styles from './styles.module.scss'

type ChatDialogProps = {
  currentChat: IChat | undefined;
}

const ChatDialog = ({ currentChat } : ChatDialogProps) => {
  const { mutate: mutateChats } = useSWR(SWRkeys.getAllChats)

  const { isDesktop } = useViewport()
  const { data: currentUser } = useCurrentUser()

  const isFrozen = currentUser?.isFrozen

  const unblockUser = useCallback(async () => {
    if (currentChat) {
      try {
        await handleUnblockUser(currentChat.receiverInfo.id)
        mutateChats([])
      } catch (err) {
        toast.error(`${I18N_GLOBAL}.serverError`)
      }
    }
  }, [currentChat, mutateChats])

  if (!currentChat) {
    return <NoDialog />
  }

  return (
    <div className={styles.wrapperChanel}>
      <ChatHeader chat={currentChat} />

      <ChatHistory currentChat={currentChat}/>
      {(isFrozen && !isDesktop) && <UserFrozenNotification className={styles.frozenNotification}/>}

      {currentChat.receiverInfo.isBlocked
        ? (
          <RegularButton
            id={ID.messengerUnblockButton}
            buttonType={RegularButtonTypes.blackNoBorder}
            leftIcon={{ name: 'hand-crossed', currentColor: 'fill', size: 24 }}
            onClick={unblockUser}
            label={`${I18N_MESSENGER}.button.unblockUser`}
            className={styles.unblock}
          />
        )
        : <MessageForm chat={currentChat}/>
      }
    </div>
  )
}

export default ChatDialog
