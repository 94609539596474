import classNames from 'classnames'
import { pick } from 'lodash-es'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyedMutator } from 'swr'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import MobileTopBar, { ITopBarIcon } from 'src/components/mobileTopBar/MobileTopBar'
import ArchiveModal from 'src/components/modal/archiveModal/ArchiveModal'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import UserFrozenNotification from 'src/components/UserFrozenNotification/UserFrozenNotification'
import { I18N_POST } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import YomeAPI from 'src/constants/network.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useFavorite } from 'src/hooks/favorite.hooks'
import { useHandleDeleteOfferOnOfferPage, useHandleShareOfferLink } from 'src/hooks/offer.hooks'
import { OFFER_STATUS_TO_PERMITTED_ACTIONS, OfferActions } from 'src/models/offerActions.model'
import { OfferStatus } from 'src/models/offerStatus.model'
import { IPropsWithOffer, SinglePost } from 'src/models/post.model'
import SuccessOfferPosted from 'src/routes/newItem/components/form/SuccessOfferPosted'
import { useCurrentUser } from 'src/services/currentUser.service'
import { useGetUser } from 'src/services/user.service'
import { useToggle } from 'src/utils/hooks.utils'
import { checkIsInactiveView, handleRepublishOffer, uppercaseTitle } from 'src/utils/post.utils'

import PostImages from '../OfferImages/OfferImages'
import PostInfo from '../OfferInfo/OfferInfo'
import WriteMsgBtn from '../postStickyInfo/postCustomerActions/WriteMsgBtn'
import PostOwnerActions from '../postStickyInfo/postOwnerActions/PostOwnerActions'
import PostActionDescription from '../postStickyInfo/postOwnerActions/PostStatusDescription'
import PostPrice from '../postStickyInfo/PostPrice'
import SimilarOffers from '../similarOffers/SimilarOffers'

import styles from './mobileOffer.module.scss'

interface IMobileOffer extends IPropsWithOffer {
  mutate: KeyedMutator<SinglePost>;
}

const MobileOffer = ({ offer, mutate }: IMobileOffer) => {
  const { t } = useTranslation()
  const { slug, isFavorite: isFavoritePost } = offer

  const offerSourceEndpoint = YomeAPI.getPublicOffer(slug)

  const { handlePostLike, favoriteIconName } = useFavorite({ slug, isFavoritePost, offerSourceEndpoint })

  const { data: author } = useGetUser(SWRkeys.getUser(offer.user.id))

  const handleShareLink = useHandleShareOfferLink(offer.title)

  const topIconsCustomer: Array<ITopBarIcon> | undefined = offer.isCreatedByCurrentUser ? undefined : [
    {
      id: ID.postPageLikePost,
      iconName: favoriteIconName,
      onClick: handlePostLike,
    },
    {
      id: ID.postPageSharePost,
      iconName: 'share',
      onClick: handleShareLink,
    },
  ]

  const handleDeleteOfferOnOfferPage = useHandleDeleteOfferOnOfferPage(offer)
  const [isArchiveModalOpen, toggleArchiveModal] = useToggle(false)
  const [isSuccessOpen, toggleSuccessOpen] = useToggle()

  const { data: currentUser } = useCurrentUser()

  const isFrozen = currentUser?.isFrozen

  const handleArchivedOfferBtnClick = useCallback(() => {
    handleRepublishOffer(slug, toggleSuccessOpen)
  }, [slug, toggleSuccessOpen])

  const linkToEdit = offer.status === OfferStatus.draft ? YomeURL.editDraft : YomeURL.editOffer

  const dpordownActions = {
    [OfferActions.archive]: {
      [OfferActions.archive]: {
        onClick: toggleArchiveModal,
        icon: 'eye-off',
      },
    },
    [OfferActions.edit]: {
      [OfferActions.edit]: {
        linkTo: linkToEdit(offer.slug),
        icon: 'pen',
      },
    },
    [OfferActions.delete]: {
      [OfferActions.delete]: {
        onClick: handleDeleteOfferOnOfferPage,
        icon: 'trash',
      },
    },
    [OfferActions.promote]: {
      [OfferActions.promote]: {
        linkTo: YomeHash.unavailable,
        icon: 'announcement',
      },
    },
    [OfferActions.republish]: {
      [OfferActions.republish]: {
        onClick: handleArchivedOfferBtnClick,
        icon: 'eye-on',
      },
    },
  }

  const actionsListForStatus = OFFER_STATUS_TO_PERMITTED_ACTIONS(offer.publishedAt)[offer.status]

  const dropdownOptions = offer.isCreatedByCurrentUser ? (
    pick(dpordownActions, actionsListForStatus)
  ) : undefined

  const isInactiveView = checkIsInactiveView(offer)

  const onSuccessModalClose = useCallback(() => {
    mutate()
    toggleSuccessOpen()
  }, [mutate, toggleSuccessOpen])

  return (
    <>
      <div className={styles.offerContainer}>
        <MobileTopBar iconButtons={topIconsCustomer} dropdownOptions={dropdownOptions}/>
        <div className={isInactiveView ? 'semitransparent' : undefined}>
          <PostImages offer={offer} showNumber/>
        </div>
        <div className={styles.postContent}>
          <div className={styles.postHeader}>
            {!isInactiveView && <PostPrice value={offer.price} />}
            <Heading
              id={ID.postPagePostTitle}
              className={classNames('mobile-h5', { semitransparent: isInactiveView })}
            >
              {uppercaseTitle(offer.title, t)}
            </Heading>
          </div>
          {(!offer.isCreatedByCurrentUser && !isInactiveView) && (
            <>
              {/* <PostFeatureList
                postFeatures={[PostFeatures.Delivery, PostFeatures.PaymentUponReceipt, PostFeatures.Refund]}
              /> */}
              <div className={styles.customerActionsButtons}>
                {isFrozen && <UserFrozenNotification />}
                <WriteMsgBtn postId={offer._id} responseTime={author?.averageResponseTime || 0} isMobile/>
                <RegularButton
                  id={ID.postPageSuggestPrice}
                  buttonType={RegularButtonTypes.transparentWithBorder}
                  linkTo={YomeHash.unavailable}
                  label={`${I18N_POST}.suggestYourPriceCapital`}
                />
              </div>
            </>
          ) }
          { offer.isCreatedByCurrentUser && (
            <PostOwnerActions
              offer={offer}
            />
          )}
          { isInactiveView && (<PostActionDescription postType={offer.status} descriptionType='guestDescription'/>)}
          <PostInfo offer={offer}/>
        </div>
        {isArchiveModalOpen && (
          <ArchiveModal<SinglePost> handleClose={toggleArchiveModal} offer={offer} mutate={mutate}/>
        )}
      </div>
      {offer.status !== OfferStatus.draft && <SimilarOffers offer={offer} />}
      { isSuccessOpen && (
        <SuccessOfferPosted
          category={offer.category!}
          slug={offer.slug}
          title={offer.title}
          onClose={onSuccessModalClose}
        />
      )}
    </>
  )
}

const MobileOfferBoundary = ({ offer, mutate }: IMobileOffer) => (
  <SWRErrorBoundary swrKey={SWRkeys.getUser(offer.user.id)}>
    <MobileOffer offer={offer} mutate={mutate}/>
  </SWRErrorBoundary>
)

export default MobileOfferBoundary
