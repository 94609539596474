import classNames from 'classnames'
import React, { memo } from 'react'
import { Field, useField, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import { useInputHandler } from 'src/hooks/form.hooks'
import { getInputOverflowError } from 'src/utils/form.utils'

import FieldWrapper, { IFieldWrapperProps } from '../FieldWrapper'

import formStyles from '../form.module.scss'
import textareaStyles from './textarea.module.scss'

interface IFormTextAreaInternalProps {
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  fieldRef?: React.RefObject<HTMLTextAreaElement>;
  id: string;
  name: string;
  placeholder?: string;
  fieldClassName?: string;
  minRows?: number;
  maxRows?: number;
  autoFocus?: boolean;
  isFrozen?: boolean;
}

interface IFormTextAreaProps extends IFormTextAreaInternalProps {
  fieldWrapperProps?: IFieldWrapperProps;
  maxSymbols?: number;
  hideCounter?: boolean;
}

const DEFAULT_MIN_ROWS = 6
const DEFAULT_MAX_ROWS = 12
const DEFAULT_MAX_SYMBOLS = 3000

const FormTextAreaInternal = ({
  onBlur,
  onKeyDown,
  name,
  fieldRef,
  fieldClassName,
  placeholder = '',
  minRows = DEFAULT_MIN_ROWS,
  maxRows = DEFAULT_MAX_ROWS,
  autoFocus,
  isFrozen,
}: IFormTextAreaInternalProps) => {
  const { t } = useTranslation()
  const { input: { onBlur: onInputBlur, ...other } } = useField(name)
  const handleBlur = useInputHandler(onInputBlur, onBlur)

  return (
    <TextareaAutosize
      {...other}
      title={name}
      ref={fieldRef}
      minRows={minRows}
      maxRows={maxRows}
      placeholder={t(placeholder)}
      className={classNames(textareaStyles.fieldTextarea, formStyles.fieldInput, fieldClassName)}
      onBlur={handleBlur}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      disabled={isFrozen}
    />
  )
}

const FormTextArea = ({
  name,
  fieldWrapperProps,
  hideCounter = false,
  maxSymbols = DEFAULT_MAX_SYMBOLS,
  ...other
}: IFormTextAreaProps) => {
  const { t } = useTranslation()
  const { values } = useFormState()
  const count = (values[name] || '').length

  return (
    <Field name={name} validate={(value) => getInputOverflowError(value?.length, t, maxSymbols)}>
      {function renderFormField() {
        return (
          <FieldWrapper
            {...fieldWrapperProps}
            name={name}
            infoRight={hideCounter ? undefined : `${count}/${maxSymbols}`}
          >
            <FormTextAreaInternal {...other} name={name} />
          </FieldWrapper>
        )
      }}
    </Field>
  )
}

export default memo(FormTextArea)
