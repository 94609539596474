import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { I18N_USER_FROZEN } from 'src/constants/i18n.constants'

import Heading from '../heading/Heading'
import Paragraph from '../paragraph/Paragraph'
import SvgIcon from '../svgIcon/SvgIcon'

import styles from './userFrozenNotification.module.scss'

interface IUserFrozenNotificationProps {
  className?: string;
}

const UserFrozenNotification = ({ className }: IUserFrozenNotificationProps) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <SvgIcon name={'exclamation-point'} size={40}/>
        <Heading
          label={`${I18N_USER_FROZEN}.header`}
          level='h2'
          className='desktop-h6 mobile-h5'
        />
      </div>

      <Paragraph className='supportive-14'>
        {t(`${I18N_USER_FROZEN}.notification.p1`)}
      </Paragraph>
      <Paragraph className='supportive-14'>
        {t(`${I18N_USER_FROZEN}.notification.p2`)}
      </Paragraph>
    </div>
  )
}

export default UserFrozenNotification
