import { useViewport } from 'src/contexts/viewportContext'
import { AvatarSizes, IUploadImageResponse } from 'src/models/upload.model'
import { useCurrentUser } from 'src/services/currentUser.service'

import UserAvatar from '../../../../../../components/avatar/UserAvatar'
import ProfileAvatarDropdown from './ProfileAvatarDropdown'

import styles from '../card.module.scss'

interface ProfileAvatarProps {
  id: string;
  username: string;
  avatar?: IUploadImageResponse;
}

const ProfileAvatar = ({ id, username, avatar }: ProfileAvatarProps) => {
  const { isDesktop } = useViewport()
  const { data: currentUser } = useCurrentUser()

  const isFrozen = currentUser?.isFrozen

  return (
    <div className={styles.avatarWithControl}>
      <UserAvatar
        userId={id}
        avatarSize={isDesktop ? AvatarSizes.small : AvatarSizes.xsmall}
        firstLetter={username.charAt(0)}
        image={avatar}
      />
      <div className={styles.cameraControl}>
        { !isFrozen && <ProfileAvatarDropdown avatarFileName={avatar?.filename} /> }
      </div>
    </div>
  )
}

export default ProfileAvatar
