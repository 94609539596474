import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { ID } from 'src/constants/id.constants'
import { useInputHandler } from 'src/hooks/form.hooks'
import { getInputOverflowError } from 'src/utils/form.utils'

import IconButton from '../button/IconButton'
import FieldWrapper, { IFieldWrapperProps } from './FieldWrapper'

import styles from './form.module.scss'

interface IFormInputInternalProps {
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  // is being executed ALONG WITH input.onChange
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // is being executed INSTEAD OF input.onChange
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  fieldRef?: React.RefObject<HTMLInputElement>;
  id: string;
  name: string;
  value?: string;
  placeholder?: string;
  inputClassName?: string;
  showClearButton?: boolean;
  autocomplete?: string;
  showCounter?: boolean;
  maxSymbols?: number;
  disabled?: boolean;
}

interface IFormInputProps extends IFormInputInternalProps {
  fieldWrapperProps?: IFieldWrapperProps;
}

const DEFAULT_INPUT_MAX_SYMBOLS = 100

const FormInputInternal = ({
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  handleChange,
  id,
  name,
  fieldRef,
  inputClassName,
  placeholder = '',
  showClearButton = false,
  autocomplete,
  disabled = false,
}: IFormInputInternalProps) => {
  const { change } = useForm()
  const { t } = useTranslation()
  const { input } = useField(name)
  const { onBlur: onInputBlur, onFocus: onInputFocus, onChange: onInputChange } = input

  const clearValue = useCallback(() => {
    change(name, '')
  }, [change, name])

  const handleInputBlur = useInputHandler(onInputBlur, onBlur)
  const handleInputFocus = useInputHandler(onInputFocus, onFocus)
  const handleInputChange = useInputHandler(onInputChange, onChange)

  return (
    <div className={styles.formFieldWrapper}>
      <input
        {...input}
        id={id}
        ref={fieldRef}
        title={name}
        placeholder={t(placeholder)}
        className={classNames(styles.fieldInput, inputClassName, { [styles.hasCloseBtn]: showClearButton })}
        onKeyDown={onKeyDown}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        onChange={handleChange || handleInputChange}
        autoComplete={autocomplete}
        disabled={disabled}
      />
      {showClearButton && (
        <IconButton
          onClick={clearValue}
          iconName='close-cross'
          className={styles.closeBtn}
          id={ID.formFieldClearForm}
          size={20}
        />
      )}
    </div>
  )
}

const FormInput = ({ name, fieldWrapperProps, showCounter = false, maxSymbols, ...other }: IFormInputProps) => {
  const { t } = useTranslation()

  return (
    <Field name={name} validate={(value) => getInputOverflowError(value?.length, t, maxSymbols)}>
      {function renderFormInput({ input }) {
        const count = (input.value || '').length

        return (
          <FieldWrapper
            {...fieldWrapperProps}
            name={name}
            infoRight={showCounter ? `${count}/${maxSymbols || DEFAULT_INPUT_MAX_SYMBOLS}` : undefined}
          >
            <FormInputInternal {...other} name={name} />
          </FieldWrapper>
        )
      }}
    </Field>
  )
}

export default memo(FormInput)
