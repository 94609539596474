import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_USER_FROZEN } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useHandleDeleteOfferOnOfferPage } from 'src/hooks/offer.hooks'
import {
  OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS,
  OFFER_STATUS_TO_PERMITTED_ACTIONS,
  OfferActions,
  TOfferActionsMap,
} from 'src/models/offerActions.model'
import { IPropsWithOffer } from 'src/models/post.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { isPostDraft } from 'src/utils/post.utils'

import DraftInfo from './DraftInfo'

import styles from './styles.module.scss'

const DraftActions = ({ offer }: IPropsWithOffer) => {
  const handleDeletePostOnPostPage = useHandleDeleteOfferOnOfferPage(offer)
  const { data: currentUser } = useCurrentUser()

  const isDisabled = currentUser?.isFrozen

  if (!isPostDraft(offer.status)) {
    return null
  }

  const offerActions: TOfferActionsMap = {
    [OfferActions.edit]: {
      linkTo: isDisabled ? undefined : YomeURL.editDraft(offer.slug),
      title: isDisabled ? `${I18N_USER_FROZEN}.editOfferButtonTooltip` : undefined,
      disabled: isDisabled,
    },
    [OfferActions.delete]: {
      onClick: handleDeletePostOnPostPage,
    },
  }

  const actionsListForStatus = OFFER_STATUS_TO_PERMITTED_ACTIONS(offer.publishedAt)[offer.status]

  return (
    <div className={styles.mainPostOwner}>
      <DraftInfo createdDate={offer.createdAt}/>
      <div className={styles.postOwnerActionButtons}>
        {actionsListForStatus.map((action, index) => {
          const actionProps = action in offerActions ? offerActions[action] : null

          return offerActions ? (
            <RegularButton
              key={action}
              buttonType={index === 0 ? RegularButtonTypes.blackNoBorder : RegularButtonTypes.transparentWithBorder}
              {...OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS[action]}
              {...actionProps}
            />
          ) : null
        })}
      </div>
    </div>
  )
}

export default DraftActions
